<template>
  <div class="top-content">
    <div class="left">
      <el-button class="add-staff" type="primary" @click="handleAddStaffClick" v-has="'goods:serve:add'">
        <el-icon>
          <plus />
        </el-icon>添加服务
      </el-button>
      <el-button type="primary" @click="handleAddCategoryClick">管理分类</el-button>
      <el-button class="tag-manage" type="primary" @click="handleAddtAGClick">管理标签</el-button>
      <el-dropdown trigger="click">
        <el-button type="primary">
          批量操作<el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-if="serveState == 2" @click="handleBatchSetStateClick(1)">上架</el-dropdown-item>
            <el-dropdown-item v-if="serveState == 1" @click="handleBatchSetStateClick(2)">下架</el-dropdown-item>
            <el-dropdown-item @click="handleBatchFeipeiClick">分配</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <div class="right">
      <el-select clearable @change="categoryChange" v-model="serveCategory" class="serveCategory" placeholder="请选择分类"
        size="large">
        <el-option v-for="item in serveCategoryList" :key="item.kindId" :label="item.kindName" :value="item.kindId">
        </el-option>
      </el-select>
      <el-select @change="serveStateChange" v-model="serveState" class="m-2" placeholder="请选择状态" size="large">
        <el-option v-for="item in optList" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <div class="search-form">
        <el-input @change="handleSearchChange" clearable class="search" prefix-icon="search" v-model="searchValue"
          placeholder="请输入关键字" />
      </div>

      <div class="search-btn">
        <el-button @click="searchClick" size="" type="primary">搜索</el-button>
      </div>
    </div>
  </div>

  <add-serve-category-dialog :dialogVisible="addCategoryDialogVisible" @changeDialogVisible="changeAddCategoryDialog">
  </add-serve-category-dialog>
  <add-tag-dialog :dialogVisible="addTagDialogVisible" @changeDialogVisible="changeAddTagDialog"></add-tag-dialog>
  <distributeDialog :category="1" v-if="distributeDialogVisible" :serveId="currentDistributeServeId"
    :dialogVisible="distributeDialogVisible" @changeDialogVisible="changeDistributeDialogVisible"></distributeDialog>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import addTagDialog from '../../../add/cpns/base-info/cpns/add-form/cpns/add-tag-dialog/add-tag-dialog.vue'
import addServeCategoryDialog from '../../../add/cpns/base-info/cpns/add-form/cpns/add-serve-category-dialog/add-serve-category-dialog.vue'
import { optList } from './config'
import emitter from '@/utils/eventbus'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import distributeDialog from '../serve-list/cpns/distribute-dialog/distribute-dialog'

export default defineComponent({
  props: {

  },
  components: {
    addTagDialog,
    addServeCategoryDialog,
    distributeDialog
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const handleAddStaffClick = () => {
      router.push('/main/serve/add')
    }

    const addCategoryDialogVisible = ref(false)
    const changeAddCategoryDialog = (flag) => {
      addCategoryDialogVisible.value = flag
    }

    const searchValue = ref('')
    const searchClick = () => {
      emitter.emit('serveTopContentSearchChange', searchValue.value)
    }

    const handleSearchChange = value => {
      if (value.length == 0) {
        emitter.emit('serveTopContentSearchChange', searchValue.value)
      }
    }


    const addTagDialogVisible = ref(false)
    const changeAddTagDialog = flag => {
      addTagDialogVisible.value = flag
    }
    const visible = ref(false)
    const serveCategory = ref('')
    const handleAddCategoryClick = () => {
      addCategoryDialogVisible.value = true
    }

    const handleBatchSetStateClick = (value) => {
      emitter.emit('serveTopContentBatchSetState', value)
    }

    const handleAddtAGClick = () => {
      addTagDialogVisible.value = true
    }

    const categoryChange = (value) => {
      emitter.emit('serveTopContentServeChange', value)
    }

    const serveStateChange = (value) => {
      emitter.emit('serveTopContentStateChange', value)
    }

    const initPage = () => {
      store.dispatch('serve/getServeCategoryListAction', COMPANY_ID)
    }
    initPage()


    const serveCategoryList = computed(() => store.state.serve.serveCategoryList)
    const serveState = ref(0)

    const distributeDialogVisible = ref(false)
    const changeDistributeDialogVisible = flag => {
      distributeDialogVisible.value = flag
    }

    const currentDistributeServeId = ref('')
    const handleBatchFeipeiClick = () => {
      emitter.emit('serveTopContentBatchBindShop')
    }
    
    return {
      handleBatchFeipeiClick,
      currentDistributeServeId,
      changeDistributeDialogVisible,
      distributeDialogVisible,
      categoryChange,
      handleBatchSetStateClick,
      serveCategory,
      serveCategoryList,
      serveStateChange,
      handleAddtAGClick,
      handleAddCategoryClick,
      changeAddTagDialog,
      addTagDialogVisible,
      changeAddCategoryDialog,
      addCategoryDialogVisible,
      visible,
      handleAddStaffClick,
      optList,
      serveState,
      searchValue,
      searchClick,
      handleSearchChange
    }

  }
})
</script>

<style scoped lang="less">
@media screen and (max-width: 1080px) {
  .top-content {
    flex-wrap: wrap;


    &:deep(.el-select) {
      margin-left: 0 !important;
    }

    .left {
      margin-bottom: 10px;

      .add-staff {
        margin-right: 10px;
      }


      .serveCategory {
        margin: 0 10px;
      }

      &:deep(.el-button) {
        margin-right: 10px;
      }
    }

  }
}

@media screen and (min-width: 1360px) {
  .top-content {
    justify-content: space-between;
  }
}

@media screen and (min-width: 1500px) {
  .top-content {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.top-content {
  display: flex;


  .search-form {
    margin-right: 10px;
  }

  .right {
    &:deep(.el-select) {
      margin-right: 10px;
    }


  }

  .left {
    display: flex;
    flex-wrap: wrap;
    margin-right: 10px;

    .tag-manage {
      margin-right: 10px !important;
    }

    &:deep(.el-select) {
      min-width: 120px;
      max-width: 180px;

    }

    &:deep(.el-input) {
      min-width: 140px;
      max-width: 180px;
      margin-right: 10px;
    }

    &:deep(.el-select) {
      margin: 0 10px;
    }

    &:deep(.el-button) {
      margin-bottom: 10px;
    }

    .search {
      min-width: 140px;
      max-width: 180px;

      &:deep(.el-button) {
        margin-bottom: 0px;
      }
    }
  }

  .right {
    display: flex;


  }
}
</style>




