<template>
  <div class="goods-edit-draw">
    <page-draw @changedDrawer="changedDrawer" :isShow="pageDialogVisible">
      <div class="goods-detail">
        <div class="top-form">
          <el-button @click="handleEditClick" type="primary" v-has="'goods:serve:edit'">编辑</el-button>
          <el-button v-if="serveDetailData && serveDetailData.enabled == 1" @click="handleOperateClick(2)" type="info">下架
          </el-button>
          <el-button v-else @click="handleOperateClick(1)" type="primary">上架</el-button>
        </div>
        <div class="goods-img">
          <page-swiper :picList="serveDetailData.picture"></page-swiper>
        </div>
        <div class="goods-name">{{ serveDetailData && serveDetailData.serveName }}</div>
        <div class="base-info">
          <div class="title">
            <div>基本信息</div>
          </div>
          <div class="base-info-list">
            <div class="info-item">
              <div class="item-name">分类:</div>
              <div class="value">{{ serveDetailData && serveDetailData.serveKindName }}</div>
            </div>
            <div class="info-item">
              <div class="item-name">标签:</div>
              <div class="value">{{ serveDetailData && serveDetailData.serveTags }}</div>
            </div>
          </div>
        </div>

        <!-- <div class="format-info">
          <div class="title">
            <div>规格</div>
          </div>
          <div class="titles">
            <div>时长</div>
            <div>规格</div>
            <div>售价</div>
          </div>
          <div class="format-list">
            <template :key="item" v-for="item in serveDetailFormatList">
              <div class="item">
                <div>{{ item.serveTimeLength }}分钟</div>
                <div>{{ item.levelName }}</div>
                <div>¥{{ (item.salePrice / 100).toFixed(2) }}</div>
              </div>
            </template>
          </div>
        </div> -->

        <div class="goods-desc">
          <div class="title goods-title">
            <div>产品介绍</div>
          </div>
          <!-- <div class="proportion">{{
              serveDetailData && serveDetailData.reservePayKind == 1 ?
                `需支付${serveDetailData.price}%` : '固定金额'
          }}</div>
          <div class="pay">{{ serveDetailData && serveDetailData.reservePayFlag == 0 ? '无需支付' : '需要支付' }}</div> -->
          <div v-html="serveDetailData && serveDetailData.serveInfo" class="desc"></div>
        </div>
      </div>
    </page-draw>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch, onUnmounted } from 'vue'
import PageDraw from '@/components/page-drawer/page-drawer.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { categoryProps } from './config'
import { useRouter } from 'vue-router'
import emitter from '@/utils/eventbus'
import pageSwiper from '@/components/page-swiper/page-swiper.vue'
import { enabledServe } from '@/service/main/serve'
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper.min.css"

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    serveId: {
      type: Number,
      default: 1
    }
  },
  components: {
    PageDraw,
    Swiper,
    SwiperSlide,
    pageSwiper
  },
  emits: ['changedDrawer','refishList'],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const pageDialogVisible = computed(() => props.dialogVisible)
    const formatList = ref([])
    const changedDrawer = (flag) => {
      emit('changedDrawer', flag)
    }

    // const serveDetailPicList = computed(() => store.state.serve.serveDetailPicList)
    // const serveDetail = computed(() => store.state.serve.serveDetail)
    // const serveDetailFormatList = computed(() => store.state.serve.serveDetailFormatList)
    const serveDetailData = computed(() => store.state.serve.serveDetailData)
    console.log(serveDetailData);
    const initPage = () => {
      // console.log(1);
      // store.dispatch('serve/getServePicAction', { relateId: props.serveId, relateKind: 1 })
      // store.dispatch('serve/getServeDetailAction', { serveId: props.serveId })
      // store.dispatch('serve/getServeFormatAction', props.serveId)
      store.dispatch('serve/getServeDetailData', { id: props.serveId })
    }
    initPage()

    const formState = useState('goods', [])

    watch(() => props.serveId, () => {
      initPage()
    },
      {
        deep: true
      })

    const handleEditClick = () => {
      router.push({
        path: '/main/serve/update', query: {
          serveId: props.serveId
        }
      })
    }
    const handleOperateClick = async() => {
      const res = await enabledServe(props.serveId)
      if (res.code == 0) {
        emit('refishList');
        // emitter.emit('refreshServeListPageContent')
        emitter.emit('refreshServeEditDrawPage')
      }
      // store.dispatch('serve/setServeStatusAction', [type, props.serveId, COMPANY_ID])
    }

    emitter.on('refreshServeEditDrawPage', () => {
      initPage()
    })


    emitter.on('refreshGoodsDrawerPage', () => {
      initPage()
    })

    onUnmounted(() => {
      emitter.off('refreshGoodsDrawerPage')
      emitter.off('refreshServeEditDrawPage')
    })

    return {
      pageDialogVisible,
      changedDrawer,
      formatList,
      UPLOAD_BASE_URL,
      ...formState,
      categoryProps,
      handleEditClick,
      handleOperateClick,
      // serveDetailPicList,
      // serveDetail,
      // serveDetailFormatList,
      serveDetailData
    }

  }
})
</script>

<style scoped lang="less">
.goods-edit-draw {
  font-size: 12px;

  .goods-desc {
    line-height: 30px;

    .goods-title {
      &::before {

        top: 6px !important;
      }
    }
  }

  .goods-detail {
    height: 100%;

    .title {
      display: flex;
      position: relative;
      justify-content: space-between;
      padding-left: 16px;
      color: rgba(80, 80, 80, 1);
      font-size: 12px;
      margin: 20px 0;

      .other {
        color: rgba(42, 130, 228, 1);

        span {
          margin: 0 6px;
          cursor: pointer;
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 4px;
        height: 20px;
        background-color: rgba(42, 130, 228, 1);
        left: 0;
        top: -4px
      }
    }

    .goods-name {
      color: rgb(42, 130, 228);
      font-size: 16px;
      font-weight: 550;
      margin: 16px 0;
    }

    .top-form {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }

    .goods-img {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      .swiper {
        width: 100%;

        .swiper-item {

          .img-box {
            img {
              height: 180px;
              width: auto;
            }
          }

          &:deep(.swiper-slide) {}

        }
      }

      .carousel {
        width: 100%;

        .swiper-item {}
      }
    }

    .base-info {
      margin-top: 22px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;

      .base-info-list {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;

        .info-item {
          display: flex;
          margin-bottom: 12px;
          width: 48%;
          margin: 0 1%;
          margin-bottom: 16px;

          .item-name {
            width: 60px;
            color: rgba(166, 166, 166, 1);
            font-size: 12px;
            margin-right: 26px;
          }

          .value {
            color: rgba(80, 80, 80, 1);
            font-size: 12px;
          }
        }
      }
    }

    .format-info {
      margin: 10px 0;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;

      .format-list {
        padding: 0 10px;

        .item {
          display: flex;
          margin: 10px 0;


          div {
            flex: 1;
            line-height: 30px;
          }
        }
      }

      .titles {
        display: flex;
        height: 45px;
        line-height: 45px;
        background-color: rgb(239, 239, 239);
        font-size: 12px;
        margin-top: 16px;
        padding: 0 10px;

        div {
          flex: 1;
        }
      }
    }
  }
}
</style>




