<template>
  <div class="add-rank-dialog">
    
    <page-dialog width="750px" height="500px"  @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="分配服务">
      <!-- <div>{{serveId}}</div> -->
      <div class="server_title_list">
        <div>当前选择分配服务项：</div>
        <div  v-for="(item,index) of serveList" :key="index"><span style="color:green">{{item.serveName}}</span> &nbsp;/&nbsp;</div>
      </div>
      
      <div class="shop-list">
        <el-table
          ref="multipleTable"
          :data="shopList"
          tooltip-effect="dark"
          height="520"
          style="width: 100%;"
          @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="80px">
          </el-table-column>
          <el-table-column
            prop="shopName"
            label="门店">
            <template #default="scope">
              <div class="table-header">
                <img style="width:50px;height: 50px;border-radius: 50%;margin-right: 10px;" :src="scope.row.picture" />
                <div>{{ scope.row.shopName }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="shopAddr"
            label="地址">
          </el-table-column>
        </el-table>
        <!-- <el-pagination  v-model:currentPage="currentPage.page" v-model:page-size="currentPage.pagesize"
          :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="total"
          @size-change="handleSizeChange" @current-change="handleCurrentChange" :pager-count="3" /> -->
      </div>
      <div class="btn">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" style="margin-left: 30px;" @click="confirm">确认分配</el-button>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, nextTick,onMounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { rankCategory } from './config'
import emitter from '@/utils/eventbus'
import { react } from '@babel/types'
import { useState } from '@/hooks'
import { batchBindShop,batchBindoutShop, allocateServe} from '@/service/main/serve'
import { ElMessage } from 'element-plus'
import { getShopDownList } from '@/service/main/shop'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    serveId: {
      type: Number,
      default: 0
    },
    serveList:{
      type:Array,
      default:()=>{
        []
      }
    },
    serveId:{
      type:Array,
      default:()=>{
        []
      }
    },
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const dialogTitle = computed(()=>{
      let titleList = ['分配服务']
      for(let item of props.serveList){
        titleList.push('（'+item.serveName+'）')
      }
      let title = titleList.join('/')
      return title
    })
    const store = useStore()
    const changeDialogVisible = (flag) => {
       emitter.emit('serverBindShopStatusChange')
      emit('changeDialogVisible', flag)
    }
    const closeDialog = () => {
      emitter.emit('serverBindShopStatusChange')
      emit('changeDialogVisible', false)
    }
    
    const formState = useState('serve', [ 'shopCount'])
    const checkList = ref([])
    const shopServeBindList = computed(() => store.state.serve.shopServeBindList)
    watch(shopServeBindList, (n) => {
      // console.log(n);
      if (n != null) {
        checkList.value = n
      }
    })
    const currentPage = reactive({
      page: 1,
      pagesize: 10,

    })

    const currentEditShop = reactive({
      id: 0,
      flag: false
    })

    watch(currentPage, () => {
      initPage()
    }, {
      deep: true
    })

    const handleSizeChange = (number) => {
      currentPage.pagesize = number
    }

    const handleCurrentChange = (number) => {
      currentPage.page = number
    }
    // const checkSelectSet=(row, index)=>{
    //   if (row.runningStatus == 1) {
    //     return true; //禁用
    //   } else {
    //     return false; //不禁用
    //   }
    // }
    const shopList=ref([])
    const total=ref(0)
    const initPage =async () => {
      const res = await getShopDownList()
      let list = res.data
      shopList.value=list
      //  total.value = res.data.total
      
    }
    initPage()

   
    const multipleSelection = ref([])
    const handleSelectionChange = (val) => {
        multipleSelection.value = val;
      }
    const confirm = async() =>{
      let array=multipleSelection.value.map(item=>{
          return item.id
        })
      const res = await allocateServe({
        serveIds: props.serveId,
        shopIds: array
      })
      if (res.code == 0) {
        closeDialog()
        initPage()
        ElMessage({
          message: '分配成功',
          type: 'success',
        })
      }
    }

    watch(() => props.serveId, (n) => {
      initPage()
    })

    
    return {
      shopList,
      changeDialogVisible,
      rankCategory,
      ...formState,
      checkList,
      handleSizeChange,
      handleCurrentChange,
      currentPage,
      currentEditShop,
      shopServeBindList,
      dialogTitle,
      total,
      // checkSelectSet,
      closeDialog,
      confirm,
      multipleSelection,
      handleSelectionChange
    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  &:deep(.el-select) {
    width: 100%;
  }
  .server_title_list{
    display: flex;
    margin-top: -15px;
    padding: 10px 0;
  }
  .shop-list {
    .titls {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      height: 50px;
      background-color: rgb(239, 239, 239);
      div {
        flex: 1;
        border-right: 1px solid rgba(0,0,0,0.5);
        padding: 0 10px;
      }
    }
    .raw_item{
      display: flex;
      width: 100%;
     
      align-items: center;
      .column_item{
        flex: 1;
        border-right: 1px solid rgba(0,0,0,0.1);
        border-bottom: 1px solid rgba(0,0,0,0.1);
        height: 50px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        
        .column_item_{
          display: flex;
          align-items: center;
          cursor:pointer;
          .choose_icon{
            width: 18px;
            height: 18px;
          }
          .choose_text{
            font-size: 13px;
            color: #bfbfbf;
            margin-left: 8px;
          }
          .choose_text_{
            color: #409eff;
          }
        }
        
        
      }
    }
    &:deep(.el-checkbox) {
      // display: inline-block;
      width: 100% !important;

      .el-checkbox__label {
        display: inline-block;
        width: 100%;

        .item {
          width: 97%;
          display: flex;
          justify-content: space-between;
          .one_item{
            flex: 1;
          }
          // .left,
          // .right {
          //   flex: 1;

          // }
        }
      }
    }
  }
  .btn{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .table-header{
    display: flex;
    align-items: center;
  }
}
</style>




