export const optList = [
  {
    label: '全部',
    value: 0
  },
  {
    label: '已上架',
    value: 1
  },
  {
    label: '已下架',
    value: 2
  }
]
