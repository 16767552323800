<template>
  <div class="add-rank-dialog">
    <page-dialog
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      title="分配服务"
    >
      <div class="shop-list">
        <div class="titls">
          <div>门店名称</div>
          <div>上架状态</div>
        </div>
        <el-scrollbar height="250px">
          <el-checkbox-group @change="handleCheckChange" v-model="checkList">
            <template :key="item" v-for="item in shopList">
              <el-checkbox
                @click="checkBoxClick(item)"
                @change="handleCheckBoxChange"
                :label="item.shopId"
              >
                <div class="item">
                  <div class="left">
                    {{ item.shopName }}
                  </div>
                  <div class="right">{{ checkList.find(ele=>ele==item.shopId)? '选中' : '未选中' }}</div>
                </div>
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </el-scrollbar>
        <el-pagination
          v-model:currentPage="currentPage.page"
          v-model:page-size="currentPage.pagesize"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="shopCount"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :pager-count="3"
        />
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch,computed } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { rankCategory } from './config'
import emitter from '@/utils/eventbus'
import { react } from '@babel/types'
import { useState } from '@/hooks'
import { nextTick } from 'process'
import { getShopList } from '@/service/main/shop'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    serveId: {
      type: Number,
      default: 0
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const formState = useState('serve', [ 'shopCount'])
    const checkList = ref([])
    const shopServeBindList=computed(()=>store.state.serve.shopServeBindList)
    watch(shopServeBindList,(n)=>{
      // console.log(n);
      if(n!=null){
        checkList.value=n
      }
    })
    const currentPage = reactive({
      page: 1,
      pagesize: 10,

    })

    const currentEditShop = reactive({
      id: 0,
      flag: false
    })

    watch(currentPage, () => {
      initPage()
    }, {
      deep: true
    })

    const handleSizeChange = (number) => {
      currentPage.pagesize = number
    }

    const handleCurrentChange = (number) => {
      currentPage.page = number
    }

    const shopList=ref([])
    const initPage =async () => {
      const res = await getShopList({
        companyId: COMPANY_ID,
        page: currentPage.page,
        pagesize: currentPage.pagesize
      })
      let list = res.data.allShops
      let newList = list.filter(ele => ele.runningStatus == 1)
      shopList.value = newList
      store.dispatch('serve/getServeShopBindListAction',props.serveId)
    }
    initPage()

    const handleCheckBoxChange = (flag) => {
      currentEditShop.flag = flag
      nextTick(() => {
        if (flag) {
          store.dispatch('serve/setShopServeBindAction', {
            serveId: props.serveId,
            shopId: currentEditShop.id

          })
        } else {
          store.dispatch('serve/removeShopServeBindAction', {
            serveId: props.serveId,
            shopId: currentEditShop.id
          })
        }
      })

    }

    const handleCheckChange = (value) => {
      // console.log(value);
    }

    const checkBoxClick = (item) => {
      // console.log(item);
      currentEditShop.id = item.shopId
    }




    watch(() => props.serveId, (n) => {
      initPage()
    })






    return {
      checkBoxClick,
      changeDialogVisible,
      rankCategory,
      ...formState,
      checkList,
      handleSizeChange,
      handleCurrentChange,
      currentPage,
      handleCheckBoxChange,
      handleCheckChange,
      currentEditShop,
      shopServeBindList,
      shopList
    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  &:deep(.el-select) {
    width: 100%;
  }
  .shop-list {
    .titls {
      display: flex;
      margin-bottom: 20px;
      div {
        flex: 1;
        line-height: 40px;
        background-color: rgb(239, 239, 239);
      }
    }
    &:deep(.el-checkbox) {
      display: inline-block;
      width: 100% !important;
      .el-checkbox__label {
        display: inline-block;
        width: 100%;
        .item {
          width: 97%;
          display: flex;
          justify-content: space-between;
          .left,.right {
            flex: 1;

          }
        }
      }
    }
  }
}
</style>




