export const breadcrumbList = [
  {
    name: '商品管理',
    path: ''
  },
  {
    name: '服务列表',
    path: ''
  }
]
