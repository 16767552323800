export const contentTableConfig = {
  propList: [
    { prop: 'serveId', label: '编号', minWidth: '60', slotName: 'serveId' },
    {
      prop: 'serveName',
      label: '服务',
      minWidth: '180',
      slotName: 'serveName'
    },
    {
      prop: 'price',
      label: '价格',
      minWidth: '100',
      slotName: 'price'
    },
    { prop: 'serveKind', label: '分类', minWidth: '120', slotName: 'serveKind' },
    {
      prop: 'serveTags',
      label: '标签',
      minWidth: '200',
      slotName: 'serveTags'
    },
    {
      prop: 'serve_time',
      label: '服务时长',
      minWidth: '120',
      slotName: 'serve_time'
    },
    {
      prop: 'prepare',
      label: '筹备时长',
      minWidth: '120',
      slotName: 'serve_prepare'
    },
    {
      prop: 'useQty',
      label: '在售门店',
      slotName: 'useQty'
    },
    {
      prop: 'serveSalesVolume',
      label: '总销量',
      slotName: 'serveSalesVolume'
    },
    { label: '操作', minWidth: '230', slotName: 'handler' }
  ],
  showIndexColumn: false,
  showSelectColumn: true
}
