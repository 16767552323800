<template>
  <div class="add-tag-dialog">
    <page-dialog @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="新增服务标签"
      :isShowFooter="true">
      <template #default>
        <div class="top-form">
          <el-input size="small" v-model="tagValue" placeholder="请输入标签名称" />
          <el-button plain color="#409EFF" class="add-btn" size="small" type="primary" @click="handleAddTagClick">新增标签
          </el-button>

        </div>
        <div class="tag-list">
          <template :key="item" v-for="item in serveTagList">
            <el-tag @dblclick="handleCheckBoxDbclick(item)" @close="handleTagClose(item)" closable>
              {{ item.tagName }}
            </el-tag>
          </template>
        </div>
      </template>

      <template #footer>
        <div class="remove">

        </div>
      </template>
    </page-dialog>
    <page-dialog title="更改标签" @changeDialogVisible="changeUpdateNameDialogVisible"
      :dialogVisible="dialogUpdateNameVisible">
      <div class="update-name-form">
        <el-input size="small" v-model="updateCategoryNameValue" placeholder="请输入标签名称" />
        <el-button color="#409EFF" plain class="sure-btn" type="primary" size="small" @click="handleUpdateTagNameClick">
          确定</el-button>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref,onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { tips } from '@/utils/tips'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import emitter from '@/utils/eventbus'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const ruleForm = reactive({
      name: ''
    })
    const companyID = ref(COMPANY_ID)
    const currentChooseTag = ref([])
    const tagValue = ref('')
    const tagList = ['全身按摩', '舒缓按摩', '上身按摩', '腿部按摩']
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const currentUpdateTagId = ref('')
    const dialogUpdateNameVisible = ref(false)
    const updateCategoryNameValue = ref('')

    const handleCheckBoxDbclick = (item) => {
      // console.log(item);
      dialogUpdateNameVisible.value = true
      currentUpdateTagId.value = item.tagId
      updateCategoryNameValue.value = item.tagName
    }

    const changeUpdateNameDialogVisible = (flag) => {
      dialogUpdateNameVisible.value = flag
    }

    const handleAddTagClick = () => {
      if (tagValue.value.trim().length == 0) {
        ElMessage({
          message: '标签名不得为空',
          type: 'warning',
        })
        return
      }

      store.dispatch('serve/addTagAction', { tagName: tagValue.value, companyId: COMPANY_ID, tagKind: 1 })
      tagValue.value=""
    }

    const initPage = () => {
      store.dispatch('serve/getServeTagAction', { companyId: COMPANY_ID, tagKind: 1 })
    }
    initPage()


    emitter.on('goodsTagListChange', () => {
      initPage()
      tagValue.value = ''
    })

    emitter.on('refreshServeTagInfoPage', () => {
      initPage()
    })

    const tagState = useState('serve', ['serveTagList'])

    const handleRemoveClick = () => {
      // console.log([...currentChooseTag.value]);
      store.dispatch('serve/removeServeTagAction', [...currentChooseTag.value])

    }

    const handleUpdateTagNameClick = () => {
      store.dispatch('serve/updateGoodsTagName', [currentUpdateTagId.value, updateCategoryNameValue.value])
      dialogUpdateNameVisible.value = false
    }

    emitter.on('updateAddServeTagNameChangeAction', () => {
      initPage()
    })

    const handleTagClose = item => {
      // console.log(item);
      store.dispatch('serve/removeServeTagAction', [item])
    }

    onUnmounted(()=>{
      emitter.off('updateAddServeTagNameChangeAction')
      emitter.off('refreshServeTagInfoPage')
      emitter.off('goodsTagListChange')
    })

    return {
      handleTagClose,
      changeDialogVisible,
      ruleForm,
      tagList,
      currentChooseTag,
      tagValue,
      handleAddTagClick,
      companyID,
      COMPANY_ID,
      ...tagState,
      handleRemoveClick,
      handleCheckBoxDbclick,
      changeUpdateNameDialogVisible,
      currentUpdateTagId,
      dialogUpdateNameVisible,
      updateCategoryNameValue,
      handleUpdateTagNameClick
    }

  }
})
</script>

<style scoped lang="less">
.add-tag-dialog {
  .update-name-form {
    display: flex;

    .sure-btn {
      margin-left: 10px;
    }
  }

  &:deep(.el-checkbox-button--small) {
    margin-top: 10px;

  }

  .remove {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tips {
      color: #ccc;
      font-size: 12px;
    }
  }

  .tag-list {
    display: flex;
    flex-wrap:wrap;
    padding-top: 20px;

    &>span {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .top-form {
    display: flex;

    .add-btn {
      margin-left: 10px;
    }
  }

  &:deep(.el-select) {
    max-width: 160px;
  }
}
</style>
