<template>
  <div class="serve-list">
    <page-content
      :dataList="serveList"
      @selectionChange="selectionChange"
      class="page-content"
      :contentTableConfig="contentTableConfig"
      :queryInfo="queryInfo"
      @tableCellClick="handleTableCellClick"
    >
      <template #serveName="scope">
        <div class="name">
          <img
            v-if="scope.row.picture != null"
            :src="scope.row.picture"
            alt
          />
          <div class="goods-name">{{ scope.row.serveName }}</div>
        </div>
      </template>
      <template #price="scope">
        <div>
          ¥ {{ scope.row.price }}
        </div>
      </template>
      <template #serveTags="scope">
        <div class="tag-list">
          <template :key="item" v-for="item in scope.row.serveTags.split(',')">
            <span class="el-tag el-tag--light">{{ item }}</span>
          </template>
        </div>
      </template>
      <template #serve_time="scope">
        <div class="serveTime">
            {{ scope.row.serveLength }}分钟
        </div>
      </template>
      <template #serve_prepare="scope">
        <div class="serveTime">
            {{ scope.row.prepare }}分钟
        </div>
      </template>
      <template #useQty="scope">{{ scope.row.serveShopCount }}家</template>
      <template #handler="scope">
        <div @click.stop="" class="handle-btns">
          <el-button plain size="mini" @click.stop="handleDetailClick(scope.row)"
            >详情</el-button
          >
          <el-button plain size="mini" @click.stop="handleEditClick(scope.row)" v-has="'goods:serve:edit'"
            >编辑</el-button
          >
          <!-- <el-button plain size="mini" @click.stop="handleDistributeClick(scope.row)">分配</el-button> -->
          <div class="handle-wrap">
            <template v-if="scope.row.enabled == 1">
              <el-switch active-value="1" inactive-value="0" v-model="active" />
            </template>
            <template v-else>
              <el-switch active-value="1" inactive-value="0" v-model="inactive" />
            </template>
            <div
              @click.stop="handleSetStateClick(scope.row)"
              class="el-switch-mask"
            ></div>
          </div>
        </div>
      </template>
    </page-content>
    <div class="el-pagination">
      <el-pagination
        v-model:currentPage="queryInfo.page"
        v-model:page-size="queryInfo.pagesize"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="serveCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <serve-edit-draw
      v-if="pageDialogVisible"
      :serveId="currentServeId"
      @changedDrawer="handleGoodsEditdDrawer"
      @refishList="refishList"
      :dialogVisible="pageDialogVisible"
    ></serve-edit-draw>

    <distribute-dialog
      v-if="distributeDialogVisible"
      :serveId="currentDistributeServeId"
      
      :dialogVisible="distributeDialogVisible"
      @changeDialogVisible="changeDistributeDialogVisible"
    ></distribute-dialog>
     <!-- 分配弹窗 -->
    <batchDistributeDialog
      v-if="distribatchDialogVisible"
      :serveId="currentServeIds"
      :serveList="serveIdList"
      :dialogVisible="distribatchDialogVisible"
      @changeDialogVisible="changeDistribatchDialogVisible"
    >
    </batchDistributeDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config'
import { useRouter } from 'vue-router'
import { COMPANY_ID } from '@/common/constant'
import { UPLOAD_BASE_URL } from '@/common/constant'
import ServeEditDraw from './cpns/serve-edit-draw/serve-edit-draw'
import store from '@/store'
import DistributeDialog from './cpns/distribute-dialog/distribute-dialog'
import emitter from '@/utils/eventbus'
import { ElMessage } from 'element-plus'
import { setServeBatchSetState, setServeStatus ,enabledServe } from '@/service/main/serve'
import batchDistributeDialog from './cpns/batch-distribute-dialog/distribute-dialog'
export default defineComponent({
  props: {

  },
  components: {
    PageContent,
    ServeEditDraw,
    DistributeDialog,
    batchDistributeDialog
  },
  setup() {
    const router = useRouter()

    const queryInfo = {
      companyId: COMPANY_ID,
      servestatus: 0,
      keyword: '',
      kindid: '',
      page: 1,
      pagesize: 10
    }

    const serveList = computed(() => store.state.serve.serveList)
    const serveCount = computed(() => store.state.serve.serveCount)

    const handleCurrentChange = page => {
      queryInfo.page = page
      initPage()
    }

    const handleSizeChange = pagesize => {
      queryInfo.pagesize = pagesize
      initPage()
    }

    emitter.on('serveTopContentServeChange', (value) => {
      queryInfo.kindid = value
    })

    const handleEditClick = (value) => {
      router.push({
        path: '/main/serve/update',
        query: {
          serveId: value.serveId
        }
      })
    }
    const refishList = () =>{
      getserveListActionData()
    }
    const getserveListActionData = () =>{
      store.dispatch('serve/getserveListAction', { ...queryInfo })
    }
    const initPage = () => {
      getserveListActionData()
    }
    initPage()

    const distributeDialogVisible = ref(false)
    const changeDistributeDialogVisible = (flag) => {
      distributeDialogVisible.value = flag
    }

    const currentServeId = ref('')
    const pageDialogVisible = ref(false)
    const handleTableCellClick = (value) => {
      // console.log('value',value)
      pageDialogVisible.value = true
      currentServeId.value = value.serveId
    }

    const handleGoodsEditdDrawer = (flag) => {
      pageDialogVisible.value = flag
    }

    const handleSetStateClick = async (item) => {
      // let status
      // if (item.enabled == 1) {
      //   status = 2
      // } else {
      //   status = 1
      // }
      const res = await enabledServe(item.serveId)
      // const res = await setServeStatus(status, item.serveId, COMPANY_ID)
      if (res.code == 0) {
        initPage()
      }
    }

    const currentDistributeServeId = ref(0)
    const handleDistributeClick = (value) => {
      currentDistributeServeId.value = value.serveId
      distributeDialogVisible.value = true
    }

    const handleDetailClick = (value) => {
      pageDialogVisible.value = true
      currentServeId.value = value.serveId
    }

    emitter.on('serveTopContentStateChange', value => {
      queryInfo.servestatus = value
    })

    emitter.on('serveTopContentSearchChange', value => {
      queryInfo.keyword = value
      initPage()
    })

    //服务与门店的绑定关系发生变化时触发
    emitter.on('serverBindShopStatusChange', value => {

      initPage()
    })


    const selectionChange = (value) => {
      serveIdList.value = value
    }

    const serveIdList = ref([])
    emitter.on('serveTopContentBatchSetState', async value => {
      // console.log(serveIdList.value);
      if (serveIdList.value.length == 0) {
        // ElMessage.error('请先选择服务!')
        return
      }
      let serveIds = []
      serveIdList.value.forEach(item => {
        serveIds.push(item.serveId)
      })
      let obj = {
        companyId: COMPANY_ID,
        status: value,
        serveIds: serveIds.join(',')
      }
      const res = await setServeBatchSetState(obj)
      if (res.code == 0) {
        initPage()
      }
    })

    const currentServeIds = ref('')
    const distribatchDialogVisible = ref(false)
    const changeDistribatchDialogVisible = flag => {
      distribatchDialogVisible.value = flag
    }

    emitter.on('serveTopContentBatchBindShop', () => {
      
      let serveIds = []
      serveIdList.value.forEach(item => {
        serveIds.push(item.serveId)
      })
      if (serveIds.length == 0) {
        ElMessage.error('请先选择服务!')
        return
      }
      // currentServeIds.value = serveIds.join()
      currentServeIds.value = serveIds
      distribatchDialogVisible.value = true
    })

    const active = ref('1')
    const inactive = ref('0')

    onUnmounted(() => {
      emitter.off('serveTopContentBatchBindShop')
      emitter.off('serveTopContentBatchSetState')
      emitter.off('serveTopContentSearchChange')
      emitter.off('serveTopContentStateChange')
      emitter.off('serveTopContentServeChange')
      emitter.off('serverBindShopStatusChange')
    })

    return {
      active,
      inactive,
      changeDistribatchDialogVisible,
      distribatchDialogVisible,
      currentServeIds,
      serveList,
      serveCount,
      handleSizeChange,
      handleCurrentChange,
      selectionChange,
      handleDetailClick,
      currentDistributeServeId,
      contentTableConfig,
      queryInfo,
      handleEditClick,
      UPLOAD_BASE_URL,
      handleTableCellClick,
      currentServeId,
      pageDialogVisible,
      handleGoodsEditdDrawer,
      refishList,
      getserveListActionData,
      handleSetStateClick,
      distributeDialogVisible,
      changeDistributeDialogVisible,
      handleDistributeClick,
      serveIdList
    }

  }
})
</script>

<style scoped lang="less">
.serve-list {
  margin-top: 20px;
  font-size: 12px;

  .serveTime {
    color: #ff6600;
  }

  .page-content {
    .handle-btns {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .handle-wrap {
        position: relative;
        margin-left: 6px;
      }

      &:deep(.el-button) {
        margin-left: 0 !important;
        margin: 4px !important;
      }
    }

    .tag-list {
      display: flex;
      flex-wrap: wrap;

      & > span {
        display: inline-block;
        margin-right: 10px;
      }
    }

    .name {
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }

    .serve-info {
      display: flex;
      align-items: center;

      .left {
        margin-right: 10px;
      }
    }

    .tag {
      span {
        margin-right: 10px;
        color: rgba(255, 255, 255, 1);
        background-color: rgba(0, 0, 0, 1);
        border-radius: 3px;
        padding: 4px 8px;
        font-size: 12px;
      }
    }
  }
}
</style>
